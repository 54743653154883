import { useTranslation } from "react-i18next";
import { Attribute } from "../../../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import { CmsSelect } from "../../../common/FormComponents";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { Select } from "antd";
import { Key } from "react";
import { useQueryRegions } from "../../../../queries/regions/lists";
import { sanatizeGuid } from "../../../../utilities/helpers/index-filtering-helper";

export const RegionListFilter = ({
  attr,
  defaultValue,
  updateDefaultValue,
}: {
  attr?: Attribute;
  defaultValue?: any;
  updateDefaultValue: (id: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const { data: regions, isLoading: isLoadingRegions } = useQueryRegions();

  return (
    <CmsSelect
      id="documentRegion"
      label={capitalizeFirstLetter(getLocalizedValue(attr?.caption) || attr?.name || "")}
      placeholder={t("entities:region")}
      value={defaultValue !== undefined ? defaultValue : undefined}
      onChange={(e) => attr?.id && updateDefaultValue(attr?.id, e)}
    >
      {!isLoadingRegions &&
        regions &&
        regions.map((region) => (
          <Select.Option key={region.isoCode} value={sanatizeGuid(region.id as string) as Key}>
            {getLocalizedValue(region.name)}
          </Select.Option>
        ))}
    </CmsSelect>
  );
};
