import { useTranslation } from "react-i18next";
import { MultilingualInputData } from "../models/MultilingualInputData";
import { validateMlInput } from "../utilities/helpers/rules-helper";

export default function useRules() {
  const { t } = useTranslation();

  const maxLength = (value: number, name: string) => {
    return {
      maxLength: {
        value: value,
        message: t("validations:inputMaxChars", { property: name, value: value }),
      },
    };
  };

  const minLength = (value: number, name: string) => {
    return {
      maxLength: {
        value: value,
        message: t("validations:inpuMinChars", { property: name, value: value }),
      },
    };
  };

  const required = (name: string) => {
    return {
      required: t("validations:inputRequired", {
        property: name,
      }),
    };
  };

  const mlTextRequired = (name: string) => {
    return {
      validate: (value: MultilingualInputData) => validateMlInput(value, name),
    };
  };

  const numbersOnly = (name: string) => {
    return {
      pattern: {
        value: /^(0|[1-9]\d*)(\.\d+)?$/i,
        message: t("validations:inputDigitsOnly", {
          property: name,
        }),
      },
    };
  };

  const lettersOnly = (name: string) => {
    return {
      pattern: {
        value: /^[a-zA-Z]+$/,
        message: t("validations:inputLettersOnly", {
          property: name,
        }),
      },
    };
  };

  const isUpperCase = (name: string) => {
    return {
      pattern: {
        value: /^[A-Z]*$/,
        message: t("validations:inputUpperCaseOnly", {
          property: name,
        }),
      },
    };
  };

  const matchesLettersLength = (value: number, name: string) => {
    const regex = new RegExp(`^[a-zA-Z]{${value}}$`);

    return {
      pattern: {
        value: regex,
        message: t("validations:inputLettersX", {
          property: name,
          value: value,
        }),
      },
    };
  };

  const isValidPhoneNumber = () => {
    return {
      pattern: {
        value: /^\+[0-9]{8,13}$/,
        message: t("validations:phoneNumber"),
      },
    };
  };

  const isValidEmail = () => {
    return {
      pattern: {
        value:
          /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/, // eslint-disable-line
        message: t("validations:email"),
      },
    };
  };

  const isValidUrl = () => {
    return {
      pattern: {
        value:
          /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/, // eslint-disable-line
        message: t("validations:url"),
      },
    };
  };

  const isValidName = (name: string) => {
    return {
      pattern: {
        value: /^[a-zA-Z0-9-. (),/]+$/,
        message: t("validations:validCharacters", {
          property: name,
        }),
      },
    };
  };

  const isValidCode = (name: string) => {
    return {
      pattern: {
        value: /^[A-Z0-9_]*$/,
        message: t("validations:code", {
          property: name,
        }),
      },
    };
  };

  return {
    maxLength,
    minLength,
    required,
    mlTextRequired,
    numbersOnly,
    lettersOnly,
    isUpperCase,
    matchesLettersLength,
    isValidPhoneNumber,
    isValidEmail,
    isValidUrl,
    isValidName,
    isValidCode,
  };
}
