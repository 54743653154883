import { Form, TreeSelect } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { AttributeSchema, getDefaultSchema, IListSchema } from "../../../models/InputComponentData";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { AttributeInputSchema } from "./AttributeInputSchema";
import { useQueryAttribute, useSaveAttribute } from "../../../queries/attributes/detail";
import { useQueryAttributeGroups } from "../../../queries/attributeGroups/lists";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import useRules from "../../../hooks/useRules";
import ControlledInput from "../../form/ControlledInput";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";
import ControlledSelect from "../../form/ControlledSelect";

export const AttributeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("attribute");
  const { push } = useHistory();
  const { id: attributeId } = useParams<Entity>();
  const context = useCmsContext();

  const {
    data: attribute,
    isLoading: isLoadingAttribute,
    refetch: refetchAttribute,
  } = useQueryAttribute(attributeId);
  const { mutateAsync: saveAttribute, isLoading: isSavingAttribute } = useSaveAttribute();
  const { data: attributeGroups, isLoading: isLoadingAttributeGroups } = useQueryAttributeGroups();
  const { required, mlTextRequired } = useRules();

  useEffect(() => {
    if (attribute) {
      context?.setDuplicateErrorMessage(t("errors:duplicateAttribute", { name: attribute.name }));

      context?.setBreadcrumbItems([
        {
          key: "attribute",
          name: attribute.name ?? t("common:new"),
        },
      ]);
    }
  }, [attribute, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
    setValue,
    getValues,
  } = useForm<Attribute>({
    mode: "onChange",
    values: attribute,
  });

  const noDataAny = (!attribute && !!attributeId) || !attributeGroups;
  const isLoadingAny =
    (isLoadingAttribute && !!attributeId) || isSavingAttribute || isLoadingAttributeGroups;

  const submit = async (attribute: Attribute) => {
    await saveAttribute(attribute);
    !attribute.id ? push(`/attributes`) : await refetchAttribute();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  type DataSchemaOptions =
    | "dataTypeSchema.decimalCount"
    | "dataTypeSchema.maxValue"
    | "dataTypeSchema.minValue"
    | "dataTypeSchema.format"
    | "dataTypeSchema.multiSelect"
    | "dataTypeSchema.maxLength"
    | "dataTypeSchema.regex"
    | "dataTypeSchema.minLength";

  return (
    <>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:country")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/attributes`)} />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <CmsForm>
        <Form.Item
          label={t("properties:group")}
          validateStatus={!errors.groupId ? "success" : "error"}
          help={errors.groupId}
          required={true}
        >
          <TreeSelect
            style={{ width: "100%", marginTop: "5px" }}
            disabled={!canUpdate || isLoadingAny}
            placeholder={t("properties:group")}
            treeDefaultExpandAll={true}
            treeData={convertGroupsForTreeSelect(attributeGroups)}
            value={getValues().groupId}
            id="groupId"
            onChange={(e) => setValue("groupId", e)}
          />
        </Form.Item>

        <ControlledSelect
          showSearch
          control={control}
          name={"dataType"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:type")}
          data={[
            { name: t("entities:country"), value: "COUNTRY" },
            { name: t("entities:province"), value: "PROVINCE" },
            { name: t("entities:currency"), value: "CURRENCY" },
            { name: t("properties:attributeTypeDateTime"), value: "DATE_TIME" },
            { name: t("entities:documentType"), value: "DOCUMENT_TYPE" },
            { name: t("entities:language"), value: "LANGUAGE" },
            { name: t("properties:attributeTypeList"), value: "LIST" },
            { name: t("entities:materialType"), value: "MATERIAL_TYPE" },
            { name: t("properties:attributeTypeMultilingualText"), value: "MULTILINGUAL_TEXT" },
            { name: t("properties:attributeTypeNumber"), value: "NUMBER" },
            { name: t("entities:organization"), value: "ORGANIZATION" },
            { name: t("entities:product"), value: "PRODUCT" },
            { name: t("entities:region"), value: "REGION" },
            { name: t("properties:attributeTypeText"), value: "TEXT" },
            { name: t("properties:attributeTypeYesNo"), value: "YES_NO" },
            { name: t("properties:attributeTypeObject"), value: "OBJECT" },
          ]}
          customOnChange={(e) => {
            switch (e as AttributeDataType) {
              case AttributeDataType.YesNo:
                setValue("defaultValue", "No");
                break;
              case AttributeDataType.Number:
                setValue("defaultValue", "0");
                break;
            }
            setValue("dataTypeSchema", getDefaultSchema(e as AttributeDataType));
            setValue("dataType", e);
          }}
        />

        <ControlledInput
          control={control}
          name={"name"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:name")}
          rules={{
            ...required(t("properties:name")),
          }}
        />

        <ControlledMultilingualText
          control={control}
          name={"caption"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:caption")}
          rules={{
            ...mlTextRequired(t("properties:caption")),
          }}
        />

        <ControlledMultilingualText
          control={control}
          name={"description"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:description")}
          rules={{
            ...mlTextRequired(t("properties:description")),
          }}
        />

        <AttributeInputSchema
          dataType={getValues().dataType as AttributeDataType}
          dataTypeSchema={getValues().dataTypeSchema as AttributeSchema}
          updateDefaultValue={(value) => setValue("defaultValue", value)}
          updateSchemaValue={(field, value) =>
            setValue(`dataTypeSchema.${field}` as DataSchemaOptions, value)
          }
          addListItem={() => {
            const items = (getValues().dataTypeSchema as IListSchema).items;
            items.push({ code: "", name: {} });
            setValue("dataTypeSchema.items", items);
          }}
          removeListItem={(index) => {
            const items = (getValues().dataTypeSchema as IListSchema).items;
            items.splice(index, 1);
            setValue("dataTypeSchema.items", items);
          }}
          readonly={!canUpdate}
          disabled={isLoadingAny}
          defaultValue={getValues().defaultValue as string}
        />
      </CmsForm>
    </>
  );
};
