import { useTranslation } from "react-i18next";
import { Attribute } from "../../../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import { CmsSelect } from "../../../common/FormComponents";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { Select } from "antd";
import { Key } from "react";
import { useQueryOrganizations } from "../../../../queries/organizations/lists";
import { sanatizeGuid } from "../../../../utilities/helpers/index-filtering-helper";

export const OrganizationListFilter = ({
  attr,
  defaultValue,
  updateDefaultValue,
}: {
  attr?: Attribute;
  defaultValue?: any;
  updateDefaultValue: (id: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const { data: organizations, isLoading: isLoadingOrganizations } = useQueryOrganizations();

  return (
    <CmsSelect
      id="documentOrganization"
      label={capitalizeFirstLetter(getLocalizedValue(attr?.caption) || attr?.name || "")}
      placeholder={capitalizeFirstLetter(t("entities:organization"))}
      value={defaultValue !== undefined ? defaultValue : undefined}
      onChange={(e) => attr?.id && updateDefaultValue(attr.id, e)}
    >
      {!isLoadingOrganizations &&
        organizations &&
        organizations.map((organization) => (
          <Select.Option
            key={organization.name}
            value={sanatizeGuid(organization.id as string) as Key}
          >
            {organization.name}
          </Select.Option>
        ))}
    </CmsSelect>
  );
};
