import { useTranslation } from "react-i18next";
import { Attribute } from "../../../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import { CmsSelect } from "../../../common/FormComponents";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { Select } from "antd";
import { Key } from "react";
import { useQueryProvinces } from "../../../../queries/provinces/list";
import { sanatizeGuid } from "../../../../utilities/helpers/index-filtering-helper";

export const ProvinceListFilter = ({
  attr,
  defaultValue,
  updateDefaultValue,
}: {
  attr?: Attribute;
  defaultValue?: any;
  updateDefaultValue: (id: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const { data: provinces, isLoading: isLoadingProvinces } = useQueryProvinces();

  return (
    <CmsSelect
      id="documentProvince"
      label={capitalizeFirstLetter(getLocalizedValue(attr?.caption) || attr?.name || "")}
      placeholder={t("entities:province")}
      value={defaultValue !== undefined ? defaultValue : undefined}
      onChange={(e) => attr?.id && updateDefaultValue(attr?.id, e)}
    >
      {!isLoadingProvinces &&
        provinces &&
        provinces.map((province, index) => (
          <Select.Option
            key={`${province.name}_${index}`}
            value={sanatizeGuid(province.id as string) as Key}
          >
            {`${province.name} (${province.isoCode2})`}
          </Select.Option>
        ))}
    </CmsSelect>
  );
};
