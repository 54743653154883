import { Form, TreeSelect } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Field } from "../../../models/templates/Field";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryField, useSaveField } from "../../../queries/fields/detail";
import { useQueryFieldGroups } from "../../../queries/field-groups/lists";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";

export const FieldDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("field");
  const { push } = useHistory();
  const { id: fieldId } = useParams<Entity>();
  const context = useCmsContext();
  const { required, mlTextRequired, isUpperCase } = useRules();

  const { data: field, isLoading: isLoadingField, refetch: refetchField } = useQueryField(fieldId);
  const { mutateAsync: saveField, isLoading: isSavingField } = useSaveField();
  const { data: fieldGroups, isLoading: isLoadingFieldGroups } = useQueryFieldGroups();

  useEffect(() => {
    if (field) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateField", {
          name: field.name,
          code: field.code,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "field",
          name: field.name ?? t("common:new"),
        },
      ]);
    }
  }, [field, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
    setValue,
    getValues,
  } = useForm<Field>({
    mode: "onChange",
    values: field,
  });

  const noDataAny = (!field && !!fieldId) || !fieldGroups;
  const isLoadingAny = (isLoadingField && !!fieldId) || isSavingField || isLoadingFieldGroups;

  const submit = async (field: Field) => {
    await saveField(field);
    !field.id ? push(`/fields`) : await refetchField();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:field")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/fields`)} />,
          !!canUpdate ? (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ) : null,
        ]}
      />
      <CmsForm>
        <Form.Item
          label={t("properties:group")}
          validateStatus={!errors.groupId ? "success" : "error"}
          help={errors.groupId}
          required={true}
        >
          <TreeSelect
            style={{ width: "100%", marginTop: "5px" }}
            placeholder={t("properties:group")}
            disabled={!canUpdate || isLoadingAny}
            treeDefaultExpandAll={true}
            treeData={convertGroupsForTreeSelect(fieldGroups)}
            value={getValues().groupId}
            id="groupId"
            onChange={(value) => setValue("groupId", value)}
          />
        </Form.Item>

        <ControlledInput
          control={control}
          name={"name"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:name")}
          rules={{
            ...required(t("properties:name")),
          }}
        />

        <ControlledInput
          control={control}
          name={"code"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:code")}
          rules={{
            ...required(t("properties:code")),
            ...isUpperCase(t("properties:code")),
          }}
        />

        <ControlledMultilingualText
          control={control}
          name={"caption"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:caption")}
          rules={{
            ...mlTextRequired(t("properties:caption")),
          }}
        />
      </CmsForm>
    </>
  );
};
