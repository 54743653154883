import { useTranslation } from "react-i18next";
import { Province } from "../../../models/reference_lists/Province";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useQueryDocumentSeriesByAttributeValues } from "../../../queries/document-series/lists";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Tooltip, Transfer } from "antd";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import { CmsFormLayout } from "../../common/FormComponents";
import { DragSortTable } from "../../common/DragSortTable";
import { rearrangeItems } from "../../../utilities/ListHelpers";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export const ProvinceDetailsModal = ({
  show,
  province,
  canUpdate = false,
  onClose,
  onSave,
}: {
  show: boolean;
  province: Province;
  canUpdate: boolean;
  onClose: () => void;
  onSave: (province: Province) => void;
}) => {
  const { t } = useTranslation();
  const { required, matchesLettersLength, isValidName } = useRules();

  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCurrencies();
  const { data: connectedDocuments, isLoading: isLoadingConnectedDocuments } =
    useQueryDocumentSeriesByAttributeValues({
      attributeName: "Issued by province",
      attributeValue: province.id as string,
    });

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
    setValue,
    getValues,
  } = useForm<Province>({
    mode: "onChange",
    values: province,
  });

  const [currencyTransfer, setCurrencyTransfer] = useState<string[]>(getValues().currencyIds);

  const submit = async (province: Province) => {
    province.currencyIds = currencyTransfer;

    onSave(province);
  };

  const [orderObj, setOrderObj] = useState<{ key?: string; title?: string; order: number }[]>();

  useEffect(() => {
    if (!!connectedDocuments) {
      enricher();
    }
  }, [isLoadingConnectedDocuments]);

  const enricher = () => {
    if (!!province.order) {
      const known = connectedDocuments?.filter((item) =>
        province.order?.includes(item.id as string),
      );
      const additions = connectedDocuments?.filter(
        (item) => !province.order?.includes(item.id as string),
      );

      const constructedKnown = known
        ?.sort((curr, next) => {
          return (
            province.order!.indexOf(curr.id as string) - province.order!.indexOf(next.id as string)
          );
        })
        .map((value, index) => ({ key: value.id, title: value.code, order: index }));

      const constructedAdditions = additions
        ?.sort((curr, next) => {
          return (
            province.order!.indexOf(curr.id as string) - province.order!.indexOf(next.id as string)
          );
        })
        .map((value, index) => ({ key: value.id, title: `(NEW) ${value.code}`, order: index }));

      if (!!constructedAdditions && !!constructedKnown) {
        setOrderObj(constructedKnown.concat(constructedAdditions));
        return;
      }

      if (!!constructedKnown) {
        setOrderObj(constructedKnown);
      }
      return;
    }

    setOrderObj(
      connectedDocuments?.map((value, index) => ({
        key: value.id,
        title: value.code,
        order: index,
      })) ?? [],
    );
  };

  const isLoadingAny = isLoadingCurrencies || isLoadingConnectedDocuments;

  return (
    <Modal
      width={700}
      title={t(`entities:province`)}
      open={show}
      footer={[
        <Button id="btnCancel" key="cancel" disabled={false} onClick={onClose}>
          {t("common:cancel")}
        </Button>,
        <Button
          id="btnSave"
          key="save"
          disabled={!isValid || isSubmitting}
          type="primary"
          onClick={handleSubmit(submit)}
        >
          {t("common:save")}
        </Button>,
      ]}
      onCancel={onClose}
    >
      <Form {...CmsFormLayout.twocolumn}>
        <ControlledInput
          control={control}
          name={"name"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:name")}
          rules={{
            ...required(t("properties:name")),
            ...isValidName(t("properties:name")),
          }}
        />
        <ControlledInput
          control={control}
          name={"isoCode2"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:isoCode")}
          rules={{
            ...required(t("properties:isoCode")),
            ...matchesLettersLength(2, t("properties:isoCode")),
          }}
        />

        <Form.Item label={t("entities:currencies")}>
          <Transfer
            dataSource={currencies?.map((data) => ({
              key: data.id,
              title: data.name,
            }))}
            targetKeys={currencyTransfer}
            onChange={(value) => setCurrencyTransfer(value as string[])}
            render={(item) => item.title as any}
          />
        </Form.Item>

        <Form.Item label={t("entities:order")}>
          <DragSortTable
            dataSource={orderObj}
            loading={isLoadingConnectedDocuments}
            onRow={() =>
              ({
                moveRow: (from, to) => {
                  if (orderObj) {
                    const arranged = rearrangeItems(orderObj, from, to);
                    setOrderObj(arranged);

                    setValue(
                      "order",
                      arranged.map((item) => item.key as string),
                      {
                        shouldTouch: true,
                        shouldDirty: true,
                        shouldValidate: true,
                      },
                    );
                  }
                },
              }) as any
            }
            columns={[
              { title: "title", dataIndex: "title", key: "title" },
              {
                title: "",
                dataIndex: "actions",
                align: "right",
                render: (value, record, index) => (
                  <>
                    <Tooltip title="Move up">
                      <Button
                        type="default"
                        disabled={!orderObj || index === 0}
                        shape="circle"
                        icon={<ArrowUpOutlined />}
                        size="small"
                        style={{ margin: "0px 2px 0px 2px" }}
                        onClick={() => {
                          if (orderObj) {
                            const arranged = rearrangeItems(orderObj, index, index - 1);
                            setOrderObj(arranged);

                            setValue(
                              "order",
                              arranged.map((item) => item.key as string),
                              {
                                shouldTouch: true,
                                shouldDirty: true,
                                shouldValidate: true,
                              },
                            );
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        type="primary"
                        disabled={!orderObj || index === orderObj.length - 1}
                        shape="circle"
                        icon={<ArrowDownOutlined />}
                        size="small"
                        style={{ margin: "0px 2px 0px 2px" }}
                        onClick={() => {
                          if (orderObj) {
                            const arranged = rearrangeItems(orderObj, index, index + 1);
                            setOrderObj(arranged);

                            setValue(
                              "order",
                              arranged.map((item) => item.key as string),
                              {
                                shouldTouch: true,
                                shouldDirty: true,
                                shouldValidate: true,
                              },
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </>
                ),
              },
            ]}
            pagination={{ pageSize: 100 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
