import { PlusOutlined } from "@ant-design/icons";
import { Button, Transfer } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Country } from "../../../models/reference_lists/Country";
import { Province } from "../../../models/reference_lists/Province";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader, CmsTable, CmsTabs } from "../../common/PageComponents";
import { ProvinceDetailsModal } from "./ProvinceDetailsModal";
import { CurrencySortingOrderModal } from "./CurrencySortingOrderModal";
import { DocumentSupplierModal } from "./DocumentSupplierModal";
import TabPane from "antd/lib/tabs/TabPane";
import { InstructionsList } from "../../data/documents/InstructionsList";
import { useQueryCountry, useSaveCountry } from "../../../queries/countries/detail";
import { useQueryCountries } from "../../../queries/countries/lists";
import { MapCountryDocumentSuppliers } from "../../../utilities/helpers/country-helper";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useQueryRegions } from "../../../queries/regions/lists";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import ControlledDatePicker from "../../form/ControlledDatePicker";
import ControlledCheckbox from "../../form/ControlledCheckbox";

export const CountryDetails = () => {
  const { t, i18n } = useTranslation();
  const { canUpdate } = useAuthorization("country");
  const { push } = useHistory();
  const { id: countryId } = useParams<Entity>();
  const context = useCmsContext();
  const { required, mlTextRequired, matchesLettersLength } = useRules();

  const [state, setState] = React.useState<{
    selectedProvince: Province | null;
    selectedCurrencySortingOrderIndex: number;
    selectedDocumentSupplierIndex: number;
    provinceModalShow: boolean;
    currencySortingOrderModalShow: boolean;
    documentSupplierModalShow: boolean;
  }>({
    selectedProvince: null,
    selectedCurrencySortingOrderIndex: -1,
    selectedDocumentSupplierIndex: -1,
    provinceModalShow: false,
    currencySortingOrderModalShow: false,
    documentSupplierModalShow: false,
  });

  const {
    data: country,
    isLoading: isLoadingCountry,
    refetch: refetchCountry,
  } = useQueryCountry(countryId);
  const { mutateAsync: saveCountry, isLoading: isLoadingSaveCountry } = useSaveCountry();
  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();
  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCurrencies();
  const { data: regions, isLoading: isLoadingRegions } = useQueryRegions();

  React.useEffect(() => {
    if (country) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateCountry", {
          isoCode2: country.isoCode2,
          isoCode3: country.isoCode3,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "country",
          name: getLocalizedValue(country?.name) ?? t("common:new"),
        },
      ]);
    }
  }, [country, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
    setValue,
    getValues,
  } = useForm<Country>({
    mode: "onChange",
    values: country,
  });

  const [regionTransfer, setRegionTransfer] = useState<string[]>(getValues().regionIds);
  const [currencyTransfer, setCurrencyTransfer] = useState<string[]>(getValues().currencyIds);

  const noDataAny =
    (!country && !!countryId) || !countries || !documentTypes || !currencies || !regions;
  const isLoadingAny =
    (isLoadingCountry && !!countryId) ||
    isLoadingCountries ||
    isLoadingDocumentTypes ||
    isLoadingCurrencies ||
    isLoadingRegions ||
    isLoadingSaveCountry;

  const submit = async (country: Country) => {
    country.regionIds = regionTransfer;
    country.currencyIds = currencyTransfer;

    await saveCountry(country);
    !country.id ? push(`/countries`) : await refetchCountry();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:country")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/countries`)} />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <CmsTabs destroyInactiveTabPane>
        <TabPane key="documentDetails" tab={t("common:details")}>
          <CmsForm>
            <ControlledMultilingualText
              control={control}
              name={"name"}
              isLoading={isLoadingAny}
              canUpdate={canUpdate}
              label={t("properties:name")}
              rules={{
                ...mlTextRequired(t("properties:name")),
              }}
            />
            <ControlledInput
              control={control}
              name={"isoCode2"}
              isLoading={isLoadingAny}
              canUpdate={canUpdate}
              label={t("properties:iso2")}
              rules={{
                ...required(t("properties:iso2")),
                ...matchesLettersLength(2, t("properties:iso2")),
              }}
            />
            <ControlledInput
              control={control}
              name={"isoCode3"}
              isLoading={isLoadingAny}
              canUpdate={canUpdate}
              label={t("properties:iso3")}
              rules={{
                ...required(t("properties:iso3")),
                ...matchesLettersLength(3, t("properties:iso3")),
              }}
            />
            <ControlledDatePicker
              label={t("properties:monetaryMemberSince")}
              control={control}
              name={"monetaryMemberSince"}
              isLoading={isLoadingAny}
              canUpdate={canUpdate}
            />
            <ControlledCheckbox
              control={control}
              name={"exportable"}
              isDisabled={!canUpdate || isLoadingAny}
              label={t("properties:exportable")}
            />

            <CmsFormItem label={t("entities:regions")}>
              <Transfer
                dataSource={regions.map((region) => ({
                  key: region.id,
                  title: getLocalizedValue(region.name),
                }))}
                disabled={!canUpdate || isLoadingAny}
                targetKeys={regionTransfer ?? getValues().regionIds}
                onChange={(value) => setRegionTransfer(value as string[])}
                render={(item) => item.title as any}
              />
            </CmsFormItem>

            <CmsFormItem label={t("entities:currencies")}>
              <Transfer
                dataSource={currencies.map((currency) => ({
                  key: currency.id,
                  title: currency.name,
                }))}
                disabled={!canUpdate || isLoadingAny}
                targetKeys={currencyTransfer ?? getValues().currencyIds}
                onChange={(value) => setCurrencyTransfer(value as string[])}
                render={(item) => item.title as any}
              />
            </CmsFormItem>

            <CmsFormItem label={t("entities:provinces")}>
              <CmsTable
                rowKey="id"
                bordered
                size={"small"}
                style={{ marginBottom: "16px" }}
                loading={isLoadingCountries}
                dataSource={
                  getValues().provinces?.sort((a, b) => {
                    const orderA = a.name ?? 0;
                    const orderB = b.name ?? 0;

                    return orderB > orderA ? -1 : orderB < orderA ? 1 : 0;
                  }) ?? []
                }
                columns={[
                  {
                    title: t("properties:name"),
                    dataIndex: "name",
                  },
                  {
                    title: t("properties:isoCode"),
                    dataIndex: "isoCode2",
                  },
                ]}
                onEdit={(id) => {
                  const province = getValues().provinces?.find((value) => value.id === id);

                  if (province) {
                    setState((prevState) => ({
                      ...prevState,
                      selectedProvince: province,
                      provinceModalShow: true,
                    }));
                  }
                }}
                onDeleteHeader={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    shape="circle"
                    disabled={isLoadingAny}
                    style={{ margin: "0px 2px" }}
                    size="small"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        selectedProvince: new Province().withCountryId(country?.id as string),
                        provinceModalShow: true,
                      }));
                    }}
                  />
                }
                onDelete={(id) => {
                  const index = getValues().provinces?.findIndex((province) => province.id === id);

                  if (index !== undefined) {
                    const provincesCopy = getValues().provinces?.slice(0);

                    provincesCopy?.splice(index, 1);
                    setValue("provinces", provincesCopy, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    });

                    setState((prevState) => ({
                      ...prevState,
                      selectedProvince: null,
                    }));
                  }
                }}
              />
            </CmsFormItem>

            <CmsFormItem label={t("entities:currencySortingOrder")}>
              <CmsTable
                rowKey="name"
                bordered
                size={"small"}
                style={{ marginBottom: "16px" }}
                loading={isLoadingCountries}
                dataSource={
                  getValues().currencySortingOrder?.map((value) => ({
                    name: value,
                  })) ?? []
                }
                columns={[
                  {
                    title: t("properties:name"),
                    dataIndex: "name",
                  },
                ]}
                onEdit={(id) => {
                  const sortingOrder = getValues().currencySortingOrder;
                  if (sortingOrder) {
                    const index = sortingOrder.findIndex((value) => value === id);

                    setState((prevState) => ({
                      ...prevState,
                      currencySortingOrderModalShow: true,
                      selectedCurrencySortingOrderIndex: index,
                    }));
                  }
                }}
                onDeleteHeader={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    shape="circle"
                    disabled={isLoadingAny}
                    style={{ margin: "0px 2px" }}
                    size="small"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        currencySortingOrderModalShow: true,
                      }));
                    }}
                  />
                }
                onDelete={(id) => {
                  const sortingOrder = getValues().currencySortingOrder;
                  if (sortingOrder) {
                    const index = sortingOrder.findIndex((value) => value === id);

                    sortingOrder?.splice(index, 1);

                    setValue("currencySortingOrder", sortingOrder, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </CmsFormItem>

            <CmsFormItem label={t("entities:documentsSuppliedByCountries")}>
              <CmsTable
                rowKey="documentTypeId"
                bordered
                size={"small"}
                style={{ marginBottom: "16px" }}
                loading={isLoadingCountries}
                dataSource={MapCountryDocumentSuppliers(
                  documentTypes,
                  countries,
                  "suppliedBy",
                  i18n.language,
                  getValues().documentsSuppliedByCountries,
                )}
                columns={[
                  {
                    title: t("entities:documentType"),
                    dataIndex: "documentType",
                  },
                  {
                    title: t("properties:code"),
                    dataIndex: "documentTypeCode",
                  },
                  {
                    title: t("properties:country"),
                    dataIndex: "country",
                  },
                ]}
                onEdit={(id) => {
                  const suppliedByCountries = getValues().documentsSuppliedByCountries;
                  if (suppliedByCountries) {
                    const index = suppliedByCountries.findIndex(
                      (value) => value.documentTypeId === id,
                    );

                    setState((prevState) => ({
                      ...prevState,
                      documentSupplierModalShow: true,
                      selectedDocumentSupplierIndex: index,
                    }));
                  }
                }}
                onDeleteHeader={
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    shape="circle"
                    disabled={isLoadingAny}
                    style={{ margin: "0px 2px" }}
                    size="small"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        documentSupplierModalShow: true,
                      }));
                    }}
                  />
                }
                onDelete={(id) => {
                  const suppliedByCountries = getValues().documentsSuppliedByCountries;
                  if (suppliedByCountries) {
                    const index = suppliedByCountries.findIndex(
                      (value) => value.documentTypeId === id,
                    );

                    suppliedByCountries?.splice(index, 1);

                    setValue("documentsSuppliedByCountries", suppliedByCountries, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </CmsFormItem>
            <CmsFormItem label={t("entities:documentsUsedByCountries")}>
              <CmsTable
                rowKey="documentTypeId"
                bordered
                size={"small"}
                style={{ marginBottom: "16px" }}
                loading={isLoadingCountries}
                dataSource={MapCountryDocumentSuppliers(
                  documentTypes,
                  countries,
                  "usedBy",
                  i18n.language,
                  getValues().documentsUsedByCountries,
                )}
                columns={[
                  {
                    title: t("entities:documentType"),
                    dataIndex: "documentType",
                  },
                  {
                    title: t("properties:code"),
                    dataIndex: "documentTypeCode",
                  },
                  {
                    title: t("properties:country"),
                    dataIndex: "country",
                  },
                ]}
              />
            </CmsFormItem>
          </CmsForm>
        </TabPane>

        <TabPane key="countryInstructions" tab={t("common:instructions")} disabled={!canUpdate}>
          <InstructionsList
            key={"countryInstructionsList"}
            instructionsList={getValues().instructions}
            onChange={(instructions) =>
              setValue("instructions", instructions, {
                shouldTouch: true,
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            instructionLocationOptions={[
              "BANKNOTE_COUNTRY",
              "COUNTERFEIT_COUNTRY",
              "DOCUMENT_COUNTRY",
            ]}
            defaultInstructionLocationOption="BANKNOTE_COUNTRY"
          />
        </TabPane>
      </CmsTabs>

      {state.provinceModalShow && (
        <ProvinceDetailsModal
          province={state.selectedProvince ?? new Province()}
          show={state.provinceModalShow}
          canUpdate={canUpdate}
          onSave={(province) => {
            const provinces = getValues().provinces?.splice(0);

            const index = provinces?.findIndex((value) => state.selectedProvince?.id === value.id);

            if (index !== undefined && index >= 0 && provinces) {
              provinces[index] = province;
            } else {
              provinces?.push(province);
            }

            setValue("provinces", provinces, {
              shouldTouch: true,
              shouldDirty: true,
              shouldValidate: true,
            });

            setState((prevState) => ({
              ...prevState,
              selectedProvince: null,
              selectedProvinceIndex: -1,
              provinceModalShow: false,
            }));
          }}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              selectedProvince: null,
              selectedProvinceIndex: -1,
              provinceModalShow: false,
            }));
          }}
        />
      )}

      <CurrencySortingOrderModal
        key={"countryCurrencyOrderModal"}
        data={getValues().currencySortingOrder ?? []}
        show={state.currencySortingOrderModalShow}
        editIndex={state.selectedCurrencySortingOrderIndex}
        onSave={(currencySortingOrder) => {
          setValue("currencySortingOrder", currencySortingOrder, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });

          setState((prevState) => ({
            ...prevState,
            currencySortingOrderModalShow: false,
            selectedCurrencySortingOrderIndex: -1,
          }));
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            currencySortingOrderModalShow: false,
            selectedCurrencySortingOrderIndex: -1,
          }))
        }
      />

      <DocumentSupplierModal
        key={"documentSupplierModal"}
        data={getValues().documentsSuppliedByCountries ?? []}
        editIndex={state.selectedDocumentSupplierIndex}
        documentTypes={documentTypes.filter(
          (documentType) =>
            getValues().documentsSuppliedByCountries === undefined ||
            !getValues().documentsSuppliedByCountries?.some(
              (value) => value.documentTypeId === documentType.id,
            ),
        )}
        countries={countries.filter((item) => item.id !== country?.id)}
        show={state.documentSupplierModalShow}
        onSave={(documentSuppliers) => {
          setValue("documentsSuppliedByCountries", documentSuppliers, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });

          setState((prevState) => ({
            ...prevState,
            documentSupplierModalShow: false,
            selectedDocumentSupplierIndex: -1,
          }));
        }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            documentSupplierModalShow: false,
            selectedDocumentSupplierIndex: -1,
          }))
        }
      />
    </>
  );
};
