import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { CmsText } from "../common/FormComponents";

export default function ControlledTextArea<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  isLoading,
  canUpdate,
  rules,
}: {
  control: Control<TFieldValues, any>;
  name: TName;
  label: string;
  isLoading: boolean;
  canUpdate: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isLoading}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref, name, disabled },
        fieldState: { error },
      }) => (
        <CmsText
          multiLine
          label={label}
          name={name}
          placeholder={label}
          required={true}
          disabled={disabled}
          readOnly={!canUpdate}
          error={error?.message}
          value={value}
          onChange={onChange}
          ref={ref}
          onBlur={onBlur}
        />
      )}
    />
  );
}
