import { useTranslation } from "react-i18next";
import { Attribute } from "../../../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import { CmsSelect } from "../../../common/FormComponents";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { Select } from "antd";
import { Key } from "react";
import { useQueryCurrencies } from "../../../../queries/currencies/lists";
import { sanatizeGuid } from "../../../../utilities/helpers/index-filtering-helper";

export const CurrencyListFilter = ({
  attr,
  defaultValue,
  updateDefaultValue,
}: {
  attr?: Attribute;
  defaultValue?: any;
  updateDefaultValue: (id: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCurrencies();

  return (
    <CmsSelect
      id="documentCurrency"
      label={capitalizeFirstLetter(getLocalizedValue(attr?.caption) || attr?.name || "")}
      placeholder={capitalizeFirstLetter(t("entities:currency"))}
      value={defaultValue !== undefined ? defaultValue : undefined}
      onChange={(e) => attr?.id && updateDefaultValue(attr.id, e)}
    >
      {!isLoadingCurrencies &&
        currencies &&
        currencies.map((currencie) => (
          <Select.Option key={currencie.id} value={sanatizeGuid(currencie.id as string) as Key}>
            {currencie.name}
          </Select.Option>
        ))}
    </CmsSelect>
  );
};
