import { PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useFormikContext } from "formik";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../../models/data/Document";
import { Note } from "../../../../models/data/Note";
import { RowButtonSet } from "../../../common/RowButtonSet";
import { NoteDetails } from "./NoteDetails";
import { useCmsContext } from "../../../../context/app/CmsContext";
import { useState } from "react";

export const Notes = () => {
  const { canUpdate } = useAuthorization("document");
  const context = useCmsContext();
  const [state, setState] = useState<{
    editModalVisible: boolean;
    editingNote: Note | null;
    editingNoteIndex: number | null;
  }>({
    editModalVisible: false,
    editingNote: null,
    editingNoteIndex: null,
  });

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<DocumentSeries>();

  const addNote = () => {
    const note = { title: "", description: "" };
    setState((prevState) => ({
      ...prevState,
      editingNote: note,
      editingNoteIndex: null,
      editModalVisible: true,
      editModalTitle: `${t("common:new")} ${t("entities:note")}`,
    }));
  };

  const editNote = async (index: number) => {
    const note = values.notes?.[index];
    if (note) {
      setState((prevState) => ({
        ...prevState,
        editingNote: note,
        editingNoteIndex: index,
        editModalVisible: true,
        editModalTitle: `${t("common:edit")} ${t("entities:note")}`,
      }));
    }
  };

  const saveNote = async (note: Note) => {
    const notes = values.notes?.slice(0);

    if (!notes) return;

    const adjustedNote = {
      ...note,
      createdBy: context?.currentUser?.name,
    };

    state.editingNoteIndex != null
      ? (notes[state.editingNoteIndex] = adjustedNote)
      : notes.push(adjustedNote);

    closeEditModal();
  };

  const deleteNote = async (index: number) => {
    const notes = values.notes?.slice(0);
    if (!notes) return;

    notes.splice(index, 1);
    setFieldValue("notes", notes);
    closeEditModal();
  };

  const closeEditModal = async () => {
    setState((prevState) => ({
      ...prevState,
      editingNote: null,
      editingNoteIndex: null,
      editModalVisible: false,
    }));
  };

  const columns = [
    { title: t("properties:title"), dataIndex: "title", key: "title" },
    {
      title: t("properties:createdBy"),
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: t("properties:created"),
      dataIndex: "created",
      key: "created",
      render: (value) => dayjs(value).format("DD-MM-YYYY"),
    },
    {
      title: t("properties:modifiedBy"),
      dataIndex: "modifiedBy",
      key: "modifiedBy",
    },
    {
      title: t("properties:modified"),
      dataIndex: "modified",
      key: "modified",
      render: (value) => dayjs(value).format("DD-MM-YYYY"),
    },
    {
      title: t("properties:numberOfAttachments"),
      dataIndex: "attachments",
      render: (_, record) => (record.attachments ? record.attachments.length : 0),
    },
    canUpdate && {
      title: (
        <Tooltip title={t("common:add")}>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            style={{ margin: "0px 2px" }}
            size="small"
            onClick={() => addNote()}
          />
        </Tooltip>
      ),
      dataIndex: "actions",
      align: "right",
      render: (text: any, record: any, index) => (
        <RowButtonSet
          onEdit={() => {
            editNote(index);
          }}
          onDelete={() => {
            deleteNote(index);
          }}
        />
      ),
      width: "120px",
    },
  ];

  return (
    <>
      <Layout style={{ minWidth: "500px", background: "#fff" }}>
        <Table
          showHeader={true}
          dataSource={values.notes}
          rowKey={(row, index) => row.id || (index as number)}
          columns={columns.filter((column) => column) as ColumnsType<Note>}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                rowIndex && editNote(rowIndex);
              },
            };
          }}
        />
        {state.editModalVisible && state.editingNote && (
          <NoteDetails
            note={state.editingNote}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                editingNote: null,
                editingNoteIndex: null,
                editModalVisible: false,
              }))
            }
            onSave={(note) => saveNote(note)}
          />
        )}
      </Layout>
    </>
  );
};
