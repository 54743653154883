import { FieldValue } from "../../models/data/FieldValue";
import { Crop } from "react-image-crop";
import { Point } from "react-lasso-select/lib/helpers";

export const constructFieldValues = (
  fieldValues: FieldValue[],
  fieldLinkId: string,
  imageId: string,
) =>
  fieldValues.map((field) => {
    const compiledField = {
      ...field,
      fieldLinkId: fieldLinkId,
      imageId: imageId,
    } as FieldValue;

    switch (field.shapeType) {
      case "RECTANGLE":
        const rectangle = {
          height: field.height,
          width: field.width,
          x: field.x,
          y: field.y,
        } as Crop;

        compiledField.height = rectangle.height;
        compiledField.width = rectangle.width;
        compiledField.y = rectangle.y;
        compiledField.x = rectangle.x;
        break;
      case "POLYGON":
        const polygon = field.points as Point[];
        compiledField.height = undefined;
        compiledField.width = undefined;
        compiledField.y = undefined;
        compiledField.x = undefined;
        compiledField.points = polygon;
        break;
      default:
        break;
    }
    return compiledField;
  }) as FieldValue[];
