import { MultilingualInputData } from "../MultilingualInputData";

export class Region {
  public id?: string;
  public name: MultilingualInputData = {};
  public isoCode?: string;
  public regionType: RegionType = RegionType.NotSpecified;
  public countryIds: string[] = [];
  public currencyIds: string[] = [];
}

export enum RegionType {
  NotSpecified = "NOT_SPECIFIED",
  Monetary = "MONETARY",
  Global = "GLOBAL",
}
